import React from "react";
import Page from "./../components/Page";
import SEO from "./../components/SEO";
import WithSideNav from "../components/WithSideNav";

import Section from "../components/Section";
import EmailForm from "../components/EmailForm";

const sideNavProps = [
  {
    title: "About Us",
    link: "/about"
  },
  {
    title: "Team & Collaborators",
    link: "/team"
  },
  {
    title: "Get Support",
    link: "/get-support"
  },
  {
    title: "Funding History",
    link: "/history"
  }
];

function TeamPage() {
  return (
    <Page>
      <SEO title="Get Support | OHIF" />
      <Section>
        <WithSideNav sideNavProps={sideNavProps} title="Get Support">
          <p>
            OHIF grant support includes funding from the National Cancer
            Institute (NCI) Informatics Technology for Cancer Research (ITCR)
            program and the Chan Zuckerberg Initiative (CZI) Essential Open
            Source Software for Science (EOSS) Program, funded through MGH with
            subcontracts to our development collaborator and OHIF Co-Founder
            organization, Radical Imaging.
          </p>
          <p>
            These grants include funding for community support such as office
            hours with Radical developers, bug fixes, mentorship, architecture
            guidance, newsletters, roadmap, and support through the community
            forum. MGH and Radical coordinate requests for support and services
            related to OHIF user or developer needs such as customization,
            feature requests, integration, migration to new versions,
            compliance, and other OHIF-related products and services supported
            by MGH and Radical Imaging.
          </p>
          <EmailForm />
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default TeamPage;
