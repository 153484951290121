import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const googleCaptcha = "6Lft-X4mAAAAAGbSOsVDtWWwXbMIV-5TbBBOSh_R";

function EmailForm() {
  const [isValid, setIsValid] = React.useState(true);

  let buttonStyle =
    "active:bg-active-blue px-8 py-2 rounded-md text-white font-bold text-lg bg-[#0944b3]";

  if (!isValid) {
    buttonStyle += " cursor-not-allowed opacity-50";
  } else {
    buttonStyle += " hover:bg-hover-blue cursor-pointer ";
  }

  return (
    <div className="max-w-screen-xl mx-auto text-aqua-pale">
      <div className="mt-10 lg:mt-14">
        <form
          action="https://api.web3forms.com/submit"
          method="POST"
          className=""
        >
          <div className="lg:-ml-8">
            <div className="mr-8">
              <div className="grid grid-cols-12 gap-y-6 ">
                <div className="flex items-start justify-end mt-2.5 col-span-2">
                  <label htmlFor="name" className="block text-right pr-3">
                    Name
                  </label>
                </div>
                <div className="col-span-10">
                <input type="hidden" name="access_key" value="b087127b-57b9-4a4e-815a-d6daa75213d2"/> 
                <input type="checkbox" name="botcheck" className="hidden" />

                  <input
                    type="text"
                    name="name"
                    placeholder="John Smith"
                    className="w-full bg-black text-white py-2 px-4 rounded-md border-2 border-secondary-light focus:outline-none focus:bg-black focus:text-white"
                    required
                  />
                </div>
                <div className="flex items-start justify-end mt-2.5 col-span-2">
                  <label htmlFor="email" className="block text-right pr-3">
                    Your Email
                  </label>
                </div>
                <div className="col-span-10">
                  <input
                    name="email"
                    type="email"
                    placeholder="email@example.com"
                    className="w-full bg-black text-white py-2 px-4 rounded-md border-2 border-secondary-light focus:outline-none focus:bg-black focus:text-white"
                    required
                  />
                </div>
                <div className="flex items-start justify-end mt-2.5 col-span-2">
                  <label htmlFor="message" className="block text-right pr-3">
                    Message
                  </label>
                </div>
                <div className="col-span-10">
                  <textarea
                    type="text"
                    name="message"
                    placeholder="Give us some details about the type of support you are interested in"
                    rows={10}
                    className="w-full bg-black text-white py-2 px-4 rounded-md border-2 border-secondary-light focus:outline-none focus:bg-black focus:text-white"
                  />
                </div>
                {/* <div className="col-start-3">
                  <ReCAPTCHA
                    sitekey={googleCaptcha}
                    onChange={() => setIsValid(true)}
                    className="mb-2"
                  />
                </div> */}
                <div className="col-start-3">
                  <button className={buttonStyle} type="submit">
                    <div className="flex flex-row items-center">Send</div>
                  </button>
                </div>
              </div>

              {/* <div className="mt-4">
                <button
                  type="submit"
                  disabled={!isValid}
                  className={classNames(
                    "w-full inline-flex items-center justify-center px-8 py-4 font-medium rounded-full shadow",
                    { "bg-greenDark hover:bg-cyan text-white": isValid },
                    {
                      "cursor-not-allowed bg-gray-500 hover:bg-gray-700":
                        !isValid
                    }
                  )}
                >
                  Send
                </button>
              </div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EmailForm;
